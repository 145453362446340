<template>
  <b-container fluid>
    <b-overlay :show="formLoader">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                <b-row>
                    <b-col sm="12" md="12" lg="12">
                        <ValidationProvider name="Progress Percentage" vid="progress" :rules="{required: true, numeric:true, min_value: 1, max_value: 100}">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="progress"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('external_research.progress_percentage') + ' (%)' }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-model="document.progress_percentage"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col sm="12" md="12" lg="12">
                        <ValidationProvider name="Description" vid="description">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="description"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('globalTrans.description') }}
                                </template>
                                <b-textarea
                                    v-model="document.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col xl="12" lg="12" sm="12">
                      <ValidationProvider name="Constraints" vid="constraints">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="constraints"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{ $t('research_manage.constraints') }}
                          </template>
                          <b-textarea
                            v-model="document.constraints"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                          </b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col xl="12" lg="12" sm="12">
                      <ValidationProvider name="Suggestions" vid="suggestions">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="suggestions"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{ $t('research_manage.suggestions') }}
                          </template>
                          <b-textarea
                            v-model="document.suggestions"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                          </b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col xl="12" lg="12" sm="12">
                      <ValidationProvider name="Other Information" vid="other_information">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="other_information"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{ $t('research_manage.other_information') }}
                          </template>
                          <b-textarea
                            v-model="document.other_information"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                          </b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                </b-row>
                <div class="row">
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                </div>
            </b-form>
        </ValidationObserver>
    </b-overlay>
  </b-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { activityProgressStore } from '../../api/routes'

export default {
  props: {
    editItemId: {
        required: true
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.editItemId) {
        this.activityId = this.editItemId
        const activity = this.getDataFromStore()
         /** For Activity */
        if (activity) {
          this.document.constraints = activity.constraints
          this.document.suggestions = activity.suggestions
          this.document.other_information = activity.other_information
        }
    }
  },
  mounted () {},
  data () {
    return {
      formLoader: false,
      activityId: 0,
      document: {
        progress_percentage: '',
        description: '',
         /** For Activity */
        constraints: '',
        suggestions: '',
        other_information: ''
      }
    }
  },
  computed: {
      currentLocale () {
        return this.$i18n.locale
    }
  },
  methods: {
    getDataFromStore () {
      const tmpData = this.$store.state.list.find(item => item.id === this.editItemId)
      return tmpData ? JSON.parse(JSON.stringify(tmpData)) : null
    },
    async saveData () {
      this.formLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      const result = await RestApi.postData(agriResearchServiceBaseUrl, `${activityProgressStore}/${this.activityId}`, this.document)

      if (result.success) {
        this.$bvModal.hide('modal-5')
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)
        this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
      } else {
        this.formLoader = false
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>

<style>
</style>
