<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay>
                    <b-row>
                    <b-col lg="11" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <ValidationProvider name="Review Remarks" vid="remarks" :rules="remark.newStatus === 1 ? 'required' : ''">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('research_manage.review_remark')}} <span v-if="remark.newStatus === 1" class="text-danger">*</span>
                                </template>
                                <b-textarea
                                rows="4"
                                id="remarks"
                                v-model="remark.remarks"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col text-right">
                                  <b-button v-if="remark.newStatus" type="submit" :variant="remark.newStatus == 1 ? 'warning' : 'primary'" class="mr-1">{{ saveBtnName }}</b-button>
                                  &nbsp;
                                  <b-button v-if="remark.newStatus" variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { agriResearchServiceBaseUrl } from '@/config/api_config'
import { activityRemarksUpdate } from '../../api/routes'
import toggleMixedStatus from '@/Utils/toggle-mixed-status'
export default {
  name: 'FormLayout',
  props: ['remarks'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      defaultValue: '',
      remark: {},
      saveBtnName: this.remarks.newStatus === 1 ? this.$t('globalTrans.reject') : this.$t('globalTrans.approve')
    }
  },
  computed: {
  },
  created () {
      if (this.remarks) {
          this.remark = this.remarks
      }
  },
  mounted () {
    core.index()
  },
  methods: {
    saveData () {
        let message = ''
        if (this.remark.newStatus === 2) {
            message = this.$t('globalTrans.approveMsg')
        } else if (this.remark.newStatus === 1) {
            message = this.$t('globalTrans.rejectMsg')
        }
        toggleMixedStatus(agriResearchServiceBaseUrl, activityRemarksUpdate, this.remark, message, ['modal-4'])
    }
  }
}
</script>
