<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="periodic-activity-monitoring" :class="'btn btn-primary text-light mr-2'">{{ $t('research_manage.periodic_activity_monitoring') }} {{ $t('globalTrans.list') }}</router-link>
            <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.export_pdf') }}
              </b-button> -->
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xl="12" lg="12" sm="12">
                    <table class="table table">
                        <tbody>
                        <tr v-if="proposal">
                          <b-th class="text-left">{{$t('external_research.project_id')}} :</b-th>
                          <b-td class="text-left">{{ proposal.proposal_auto_id }}</b-td>
                          <b-th class="text-left">{{$t('external_research.project_title')}} :</b-th>
                          <b-td class="text-left">{{ ($i18n.locale==='en') ? proposal.project_title : proposal.project_title_bn }}</b-td>
                          <b-th class="text-left">{{$t('external_research.thematic_area')}} :</b-th>
                          <b-td class="text-left">{{ ($i18n.locale==='en') ? proposal.thematic_area_name : proposal.thematic_area_name_bn }}</b-td>
                        </tr>
                      </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-overlay :show="loadingState">
              <b-row>
                 <b-col lg="12" sm="12">
                  <template>
                        <div style="font-size:12px; background-color: #337982; padding:6px">
                            <h4 class="text-white text-center">{{ $t('research_manage.periodic_activity_monitoring') }} {{ $t('globalTrans.details') }}</h4>
                        </div>
                    </template>
                 </b-col>
              </b-row>
              <b-row id="form">
                <div class="col-md-12">
                    <div class="col-md-12">
                        <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" show-empty>
                        <template v-slot:cell(index)="data">
                            {{ $n(data.index + 1) }}
                        </template>
                        <template v-slot:cell(start_date)="data">
                            {{ data.item.start_date | dateFormat }}
                        </template>
                        <template v-slot:cell(end_date)="data">
                            {{ data.item.end_date | dateFormat }}
                        </template>
                        <template v-slot:cell(status)="data">
                            <span class="badge badge-info" v-if="data.item.activity_status === 0">{{$t('globalTrans.not_started')}}</span>
                            <span class="badge badge-primary" v-else-if="data.item.activity_status === 1">{{$t('globalTrans.ongoing')}}</span>
                            <span class="badge badge-success" v-else-if="data.item.activity_status === 2">{{$t('globalTrans.completed')}}</span>
                            <span class="badge badge-danger" v-else>{{ currentLocale == 'bn' ? 'বিচারাধীন (অনুমোদন প্রয়োজন)' : 'Pending (Need Approval)' }}</span>
                        </template>

                        <!-- <template v-slot:cell(attachment)="data">
                            <span v-for="(document) in data.item.docs" :key="document.id">
                              <template v-if="document.attachment">
                                <a class="ml-2" :href="agriResearchServiceBaseUrl +'storage/activity-docs/'+ document.attachment" target="_blank" rel="noopener noreferrer">
                                    <i class="fa fa-download" aria-hidden="true"></i>
                                </a>
                              </template>
                            </span>
                        </template> -->

                        <!-- Remarks -->
                        <!-- <template v-slot:cell(remarks)="data">
                            <b-button v-if="data.item.activity_status !== 3" v-b-modal.modal-4 variant="iq-bg-info" class="btn btn-info" size="sm" title="Reject" @click="note(data.item)">{{$t('globalTrans.remarks')}}</b-button>
                            <span class="d-flex">
                              <b-button v-if="data.item.activity_status === 3" v-b-modal.modal-4 variant="iq-bg-success" class="btn btn-success mr-1" size="sm" title="approve" @click="note(data.item, status=2)"><i class="fa fa-check m-0" aria-hidden="true"></i></b-button>
                              <b-button v-if="data.item.activity_status === 3" v-b-modal.modal-4 variant="iq-bg-danger" class="btn btn-danger" size="sm" title="Reject" @click="note(data.item, status=1)"><i class="fa fa-ban m-0" aria-hidden="true"></i></b-button>
                            </span>
                        </template> -->

                        <template v-slot:cell(progress)="data">
                          <span v-if="proposal.is_report_submit > 0">
                            {{ currentLocale == 'bn' ? 'প্রজেক্ট রিপোর্ট জমা হয়েছে' : 'Project Report Submitted' }}
                          </span>
                          <span v-else>
                            <b-button v-b-modal.modal-5 variant="iq-bg-info" class="btn btn-light" size="sm" title="Progress" @click="note(data.item)">
                              {{ `${$t('research_manage.progress')}` }}
                            </b-button>
                          </span>
                        </template>

                        <template v-slot:cell(action)="data">
                            <b-button v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="note(data.item)">
                              <i class="ri-eye-fill m-0"></i>
                            </b-button>
                        </template>
                    </b-table>
                    </div>
                  <!-- </fieldset> -->
                </div>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <Remarks :remarks="remarks" :key="itemId"/>
        </p>
    </b-modal>

    <!-- Progress -->
    <b-modal id="modal-5" size="lg" :title="`${$t('globalTrans.add')} ${$t('research_manage.progress')}`" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <AddProgress :editItemId="itemId" />
    </b-modal>

    <!-- View Progress -->
    <b-modal id="modal-6" size="xl" :title="`${$t('globalTrans.view')} ${$t('research_manage.progress')}`" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <ViewProgress :editItemId="itemId" />
    </b-modal>
  </b-container>
</template>
<script>
import Remarks from './Remarks'
import AddProgress from './AddProgress'
import ViewProgress from './ViewProgress'
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { periodicActivityMonitoringShow } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import ExportPdf from './export_pdf_details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Remarks,
        AddProgress,
        ViewProgress
    },
    created () {
      if (this.$route.query.id) {
          this.loadData()
          this.proposal = this.getProjectData()
      }
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      agriResearchServiceBaseUrl: agriResearchServiceBaseUrl,
      activityData: {},
      objectiveDetails: [],
      remarks: {},
      itemId: 0,
      proposal: null
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('external_research.activity_name'), class: 'text-center' },
            { label: this.$t('external_research.specific_objectives'), class: 'text-center' },
            { label: this.$t('external_research.start_date'), class: 'text-center' },
            { label: this.$t('external_research.end_date'), class: 'text-center' },
            { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('research_manage.progress'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
                { key: 'index' },
                { key: 'activity_name_bn' },
                { key: 'specific_obj_bn' },
                { key: 'start_date' },
                { key: 'end_date' },
                { key: 'status' },
                { key: 'progress' },
                { key: 'action' }
            ]
        } else {
            keys = [
                { key: 'index' },
                { key: 'activity_name' },
                { key: 'specific_obj_en' },
                { key: 'start_date' },
                { key: 'end_date' },
                { key: 'status' },
                { key: 'progress' },
                { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
  },
  methods: {
    getProjectData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
        return tmpData !== undefined ? JSON.parse(JSON.stringify(tmpData)) : null
    },
    async loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(agriResearchServiceBaseUrl, `${periodicActivityMonitoringShow}/${this.$route.query.id}`)
        if (result.success) {
          this.objectiveDetails = result.data.objective_details
          this.activityData = result.data.acitivity_data

          this.$store.dispatch('setList', this.getRelationalData((result.data.acitivity_data.activities)))
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const tmpObj = this.objectiveDetails.find(el => el.id === item.specific_obj_id)

        const newData = {
          specific_obj_en: tmpObj !== undefined ? tmpObj.specific_objective : '',
          specific_obj_bn: tmpObj !== undefined ? tmpObj.specific_objective_bn : ''
        }

        return Object.assign({}, item, newData)
      })

      return listData
    },
    note (item, status) {
      this.remarks.id = item.id
      this.remarks.newStatus = status
      this.remarks.remarks = item.remarks
      this.itemId = item.id
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
